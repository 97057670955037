<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

      <v-card>
        <v-card-text>
          <v-row v-if="isSearching != null">
            <v-col cols="12" v-if="this.tab == 1">
              <v-row>
                <v-col cols="12" md="3" lg="3" >
                  <v-menu
                    ref="menu_search_tranferred_date_from"
                    v-model="menu_search_tranferred_date_from"
                    :close-on-content-click="false"
                    :return-value.sync="transferred_date_from"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computed_search_tranferred_date_from"
                        @change="value => computed_search_tranferred_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="transferred_date_from" @input="menu_search_tranferred_date_from = false, $refs.menu_search_tranferred_date_from.save(transferred_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" lg="3" >
                  <v-menu
                    ref="menu_search_tranferred_date_to"
                    v-model="menu_search_tranferred_date_to"
                    :close-on-content-click="false"
                    :return-value.sync="transferred_date_to"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computed_search_tranferred_date_to"
                        @change="value => computed_search_tranferred_date_to = value"
                        autocomplete="off"
                        label="สิ้นสุด"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="transferred_date_to" @input="menu_search_tranferred_date_to = false, $refs.menu_search_tranferred_date_to.save(transferred_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" lg="3" align="center">
                  <v-btn @click="Searching(1)" color="primary" class="mr-3">ค้นหา</v-btn>
                  <v-btn @click="SearchingClearData()" outlined color="primary" class="mr-3">Reset</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <!-- ค้นหา 1 -->
                <v-col cols="12">
                  <v-data-table :headers="headers1" :items="showdataTable1" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                    <template v-slot:item.showDetail="{ item }">
                      <span href="#" @click="ShowDetailTab1(item.RealTransferDatetime, item.SumCOD)"><v-icon color="primary">mdi-tooltip-text</v-icon></span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog_detail" scrollable persistent width="auto" >
        <v-card style="background-color:#f3f4f8;" class="my-0">
          <v-card-title>
            <span class="headline">แสดงรายการ Order </span>
            <font size="1" @click="export_excel()"><v-icon color="success" class="ml-5">mdi-file-excel</v-icon><a href="#">Export Excel</a></font>
            <v-spacer></v-spacer>
            <v-icon color="danger" @click="dialog_detail = false">mdi-close-circle-outline</v-icon>
          </v-card-title>
          <v-card-text>
            <v-row >
              <v-col cols="12">
                <v-tabs v-model="detail_tabSelect">
                  <v-tab @click="detail_changeTab(1)">จัดส่งสำเร็จ {{ this.tab_data_detail_success.length }} ชิ้น</v-tab>
                  <v-tab @click="detail_changeTab(2)">ระหว่างจัดส่ง {{ this.tab_data_detail_sending.length }}  ชิ้น</v-tab>
                  <v-tab @click="detail_changeTab(3)">ตีกลับ {{ this.tab_data_detail_return.length }}  ชิ้น</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row v-show="this.detail_tab == 1" style="height: 600px;">
              <v-col cols="12" >
                <v-data-table :headers="headers_detail" :items="tab_data_detail_success" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                  <template v-slot:item.BankName="{ item }">
                    {{ item.BankName }}<br>
                    {{ item.AccountName }}<br>
                    {{ item.AccountNumber }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-show="this.detail_tab == 2" style="height: 600px;">
              <v-col cols="12" >
                <v-data-table :headers="headers_detail" :items="tab_data_detail_sending" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                  <template v-slot:item.ID="{ item }">
                    <a href="#" class="text-info" @click="show_detail_order(item.ID)">PA{{ FormatOrder(item.ID) }}</a>
                  </template>
                  <template v-slot:item.BankName="{ item }">
                    {{ item.BankName }}<br>
                    {{ item.AccountName }}<br>
                    {{ item.AccountNumber }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-show="this.detail_tab == 3" style="height: 600px;">
              <v-col cols="12" >
                <v-data-table :headers="headers_detail" :items="tab_data_detail_return" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                  <template v-slot:item.ID="{ item }">
                    <a href="#" class="text-info" @click="show_detail_order(item.ID)">PA{{ FormatOrder(item.ID) }}</a>
                  </template>
                  <template v-slot:item.BankName="{ item }">
                    {{ item.BankName }}<br>
                    {{ item.AccountName }}<br>
                    {{ item.AccountNumber }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions >
            <v-row align="center" justify="center" class="mx-0">
              <v-col cols="12" align="center" justify="center">ยอดรวม COD ทั้งหมด <span class="text-success">{{ this.dialog_detail_Sum_COD }}</span> บาท</v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { branchOverviewService} from '@/website/global'
  import { AlertError } from '@/website/global_alert'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      isSearching: null,
      menu_search_tranferred_date_from: false,
      transferred_date_from: null,
      menu_search_tranferred_date_to: false,
      transferred_date_to: null,

      tabSelect: 0,
      tab: 1,
      showdataTable: [],
      showdataTable1: [],
      showdataTable2: [],
      showdataTable3: [],

      tab_data_detail_success: [],
      tab_data_detail_sending: [],
      tab_data_detail_return: [],
      tab_data_detail_all_status: [],

      detail_tabSelect:0,
      detail_tab:1,

      dialog_detail: false,
      dialog_detail_num: 0,
      dialog_detail_Sum_COD: 0,

      headers1: [
        { text: 'วันที่เงินเข้า', align: 'left', sortable: false, value: 'RealTransferDatetime' },
        { text: 'ธนาคาร', align: 'left', value: 'Bank', sortable: false },
        { text: 'ชื่อบัญชี', align: 'left', value: 'AccountName', sortable: false },
        { text: 'หมายเลขบัญชี', align: 'left', value: 'AccountNumber', sortable: false },
        { text: 'ยอดรวม(COD)', align: 'right', value: 'SumCOD', sortable: false },
        { text: 'จำนวนพัสดุ ', align: 'right', value: 'CountParcel', sortable: false },
        { text: 'รายละเอียด', align: 'center', value: 'showDetail', sortable: false },
      ],

      headers2: [
        { text: 'วันที่ส่ง', align: 'left', value: 'SendToExpressDateTime', sortable: false },
        { text: 'ระหว่างจัดส่ง', align: 'center', value: 'CountOnTheWay', sortable: false },
        { text: 'ตีกลับ', align: 'center', value: 'CountReturn', sortable: false },
        { text: 'ส่งสำเร็จ', align: 'center', value: 'CountSuccess', sortable: false },
        { text: '%สำเร็จ', align: 'right', value: 'PercentComplete', sortable: false },
        { text: 'COD ทั้งหมด', align: 'right', value: 'SumCOD', sortable: false },
        { text: 'COD ที่โอนแล้ว', align: 'right', value: 'SumTransfered', sortable: false },
        { text: 'รายละเอียด', align: 'center', value: 'showDetail', sortable: false },
      ],

      headers3: [
        { text: 'สินค้า', align: 'lef', value: 'Name', sortable: false, width: "30%" },
        { text: 'สำเร็จ', align: 'right', value: 'sentSuccess', sortable: false, width: "10%" },
        { text: 'ตีกลับ', align: 'right', value: 'return', sortable: false , width: "10%"},
      ],

      headers_detail: [],
      headers_detail_success: [
        { text: 'สร้างวันที่', align: 'left', value: 'CreatedDatetime', sortable: false },
        { text: 'TrackingNo', align: 'center', value: 'TrackingNo', sortable: false },
        { text: 'ชื่อผู้รับ', align: 'left', value: 'ShipName', sortable: false },
        { text: 'จัดส่งสำเร็จ', align: 'left', value: 'ParcelSuccessDateTime', sortable: false },
        { text: 'COD', align: 'right', value: 'CODAmount', sortable: false },
        { text: 'ธนาคาร', align: 'left', value: 'BankName', sortable: false },
        { text: 'วันที่เงินเข้า', align: 'left', value: 'RealTransferDatetime', sortable: false },
      ],
      headers_detail_sending: [
        { text: 'สร้างวันที่', align: 'left', value: 'CreatedDatetime', sortable: false },
        { text: 'TrackingNo', align: 'center', value: 'TrackingNo', sortable: false },
        { text: 'ชื่อผู้รับ', align: 'left', value: 'ShipName', sortable: false },
        { text: 'COD', align: 'right', value: 'CODAmount', sortable: false },
        { text: 'ธนาคาร', align: 'left', value: 'BankName', sortable: false },
      ],
      headers_detail_return: [
        { text: 'สร้างวันที่', align: 'left', value: 'CreatedDatetime', sortable: false },
        { text: 'TrackingNo', align: 'center', value: 'TrackingNo', sortable: false },
        { text: 'ชื่อผู้รับ', align: 'left', value: 'ShipName', sortable: false },
        { text: 'COD', align: 'right', value: 'CODAmount', sortable: false },
        { text: 'วันที่ตีกลับ', align: 'left', value: 'ReturnedDateTime', sortable: false },
      ],
    }),
    computed: {
      computed_search_tranferred_date_from() {
        return this.formatDate(this.transferred_date_from)
      },
      computed_search_tranferred_date_to() {
        return this.formatDate(this.transferred_date_to)
      },
    },
    async created() {
      if(localStorage.getItem('customerID')==null){
        this.$router.push('/customer-login')
      }
      this.changeTab(this.tab)
      this.Searching(null)
      this.page_loading = false
    },
    methods: {
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date == null){
          // const [year, month, day] = date.split('-')
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      changeTab (tab) {
        if (tab == 1){
          this.tabSelect = 0
        }else if(tab == 2) {
          this.tabSelect = 1
        }
        this.tab = tab
      },

      detail_changeTab (detail_tab) {
        if (detail_tab == 1){
          this.detail_tabSelect = 0
          this.headers_detail = this.headers_detail_success
        }else if(detail_tab == 2) {
          this.detail_tabSelect = 1
          this.headers_detail = this.headers_detail_sending
        }else if(detail_tab == 3) {
          this.detail_tabSelect = 2
          this.headers_detail = this.headers_detail_return
        }
        this.detail_tab = detail_tab
      },

      async Searching (isSearch) {
        this.isSearching = false
        this.loading = true
        this.changeTab(this.tab)
        if (this.transferred_date_from != null && this.transferred_date_to != null) {

          this.transferred_date = [
            this.transferred_date_from,
            this.transferred_date_to
          ]
        } else {
          this.transferred_date= []
        }
        let response = await axios.post(branchOverviewService+'order/cust_report', {
            'customerID':localStorage.getItem('customerID'),
            'transferred_date_range': this.transferred_date
        })
        if (response.data.status == 'Success') {
          if(isSearch == null) {
            this.showdataTable1 = response.data.result
          } else if (isSearch == 1) {
            this.showdataTable1 = response.data.result
          }
          if(response.data.resultDate != null){
            if (response.data.resultDate.length == 0) {
              var nowDate = new Date()
              this.transferred_date_to = nowDate.toISOString().substr(0, 10)
              nowDate.setDate(nowDate.getDate()-7)
              this.transferred_date_from = nowDate.toISOString().substr(0, 10)
            } else {
              this.transferred_date_from = response.data.resultDate[0]
              this.transferred_date_to = response.data.resultDate[1]
            }
          }
          this.isSearching = true
          this.loading = false
        } else {
          this.AlertError()
          this.loading = false
        }
        this.loading = false
      },

      SearchingClearData () {
        var nowDate = new Date()
        this.transferred_date_to = nowDate.toISOString().substr(0, 10)
        nowDate.setDate(nowDate.getDate()-7)
        this.transferred_date_from = nowDate.toISOString().substr(0, 10)
      },

      async ShowDetailTab1 (dates, SumCOD) {
        this.dialog_detail_Sum_COD = SumCOD
        this.loading = true
        this.changeTab(this.tab)
        // this.dialog_detail = true
        let response = await axios.post(branchOverviewService+'order/cust_report_detail', {
            'customerID':localStorage.getItem('customerID'),
            'transferred_date_range': dates
        })
        if (response.data.status == 'Success') {
          // if(response.data.length != 0){
          this.tab_data_detail_success = response.data.data_detail_success
          this.tab_data_detail_sending = response.data.data_detail_sending
          this.tab_data_detail_return = response.data.data_detail_return
          this.tab_data_detail_all_status = response.data.result_all_status
          this.detail_changeTab(1)
          this.loading = false
          this.dialog_detail = true
          // }
        } else {
          this.AlertError()
          this.loading = false
        }
        this.loading = false
      },

      async export_excel () {
        this.loading = true
        this.changeTab(this.tab)
        if(this.tab_data_detail_all_status.length > 0){
          let response = await axios.post(branchOverviewService+'order/detail_export_excel', {
              'data_export': this.tab_data_detail_all_status
          })
          if (response.data.status == 'Success') {
            var filename = response.data.this_path
            window.open(branchOverviewService+'order/download/'+filename, "_blank");
          } else {
            this.AlertError()
          }
        } else {
          this.AlertError()
        }
        this.loading = false
      },
      AlertError,
    }
  }
</script>
